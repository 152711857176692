import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from 'react';
import {setCurrentUser} from '../../store/reducers/user/user.actions';
import {useNavigate} from 'react-router-dom';
import {getUser} from "../../store/reducers/user/user.selector";
import {sendLogoutRequest} from "../../lib/Requests";
import {setMessage} from "../../store/reducers/messages/messages.actions";
import {MessageTypesEnum} from "../../enums/message-types.enum";

export default function SignOut() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const currentUser = useSelector(getUser);

    useEffect(() => {
        const sendRequest = async () => {
            await sendLogoutRequest(currentUser.access_token);
            navigate("/bejelentkezes");

            dispatch(setMessage({
                message: "Sikeres kijelentkezés!",
                type: MessageTypesEnum.SUCCESS
            }));
            dispatch(setCurrentUser(null));
        }

        sendRequest();
    }, []);

    return <h6>Sikeres kijelentkezés!</h6>
}
import {ProductModel} from "../../models/products.model";
import {DataGrid, GridColDef, GridEventListener, huHU} from "@mui/x-data-grid";
import {NewsletterModel, PostModel} from "../../../../models";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {Stack, Tooltip} from "@mui/material";
import {TableIcon} from "./posts-table.component";
import {useDispatch} from "react-redux";
import {unsubscribeNewsLetter} from "../../lib/Requests";
import {MessageTypesEnum} from "../../enums/message-types.enum";
import {setMessage} from "../../store/reducers/messages/messages.actions";

interface ComponentProps {
    entries: NewsletterModel[];
}


const OPERATION_CELL_WIDTH = 70;

const columns: GridColDef[] = [
    { field: 'id', headerName: 'Id', width: 70 },
    { field: 'email', headerName: 'E-mail cím', width: 200 },
    { field: 'identifier', headerName: 'Azonosító', width: 400 },
    {
        field: 'delete', headerName: '', width: OPERATION_CELL_WIDTH, renderCell: (params) => {
            return (
                <Stack flexDirection="row">
                    <TableIcon>
                        <Tooltip title={"Delete"}>
                            <DeleteForeverIcon />
                        </Tooltip>
                    </TableIcon>
                </Stack>
            );
        },
    }
];

export default function NewsLetterTable({ entries } : ComponentProps)
{
    const dispatch = useDispatch();

    const handleTableDataClick: GridEventListener<'cellClick'> = async (params, event, details) =>
    {
        const { field, row } = params;

        if (field === 'delete') {
            unsubscribeNewsLetter(row.identifier)
                .then(() => {
                    dispatch(setMessage({
                        message: "Sikeresen törölted a feliratkozót!",
                        type: MessageTypesEnum.SUCCESS,
                    }))
                })
                .catch(() => {
                    dispatch(setMessage({
                        message: "Törlés sikertelen!",
                        type: MessageTypesEnum.ERROR,
                    }))
                })
        }
    };

    return <div className='table'>
        <DataGrid
            className="table"
            rows={entries.map((entry: NewsletterModel, i: number) => ({
                ...entry,
                id: i + 1,
                delete: ""
            }))}
            onCellClick={handleTableDataClick}
            sx={{ height: 600, width: "100%" }}
            columns={columns}
            initialState={{
                pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                },
            }}
            pageSizeOptions={[5, 10]}
            localeText={huHU.components.MuiDataGrid.defaultProps.localeText}
        />
    </div>;
}
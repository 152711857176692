import {Button} from "@mui/material";

interface ComponentProps {
    title: string;
    type: string | "SIGN_IN" | "CANCEL" | "SAVE" | "ADD",
    action?: any;
    disabled?: boolean;
}

function returnStyles(type: string) {
    const styles = {
        color: "#111",
        backgroundColor: "#1A5D1A",
        border: "none",
        borderRadius: "30px",
        textTransform: "capitalize",
        fontSize: "1.2em",
        padding: "5px 20px",
        "&:hover": {
            backgroundColor: "#1A5D1A",
            color: "#fff",
            border: "none",
        }
    }

    switch (type) {
        case "DELETE":
            styles.backgroundColor = "red";
            styles.color = "#fff";
            styles.border = "none";
            break;
        case "SAVE":
            styles.backgroundColor = "#1A5D1A";
            styles.color = "#fff";
            styles.border = "none";
            break;
        case "ADD":
            styles.backgroundColor = "#F86F03";
            styles.color = "#fff";
            styles.border = "none";
            break;
        case "SIGN_IN":
            styles.backgroundColor = "#F86F03";
            styles.color = "#fff";
            styles.border = "none";
            break;
        case "CANCEL":
            styles.backgroundColor = "#F86F03";
            styles.color = "#fff";
            styles.border = "none";
            break;
        case "DARK":
            styles.backgroundColor = "#ccc";
            styles.color = "#111";
            styles.border = "none";
            break;
    }

    return styles;
}

export default function ActionButton({title, type, action, disabled}: ComponentProps) {
    return <Button sx={returnStyles(type)} onClick={action} disabled={disabled}>{title}</Button>
}
import createAction from '../../../lib/reducer.utils';
import { MessageModel } from '../../../models/message.model';

export const MessagesActions = {
  "SET_MESSAGE": "Message/SET_MESSAGE",
  "SET_TYPE": "Message/Type"
}

export const setMessage = (payload: MessageModel) => createAction({
  type: MessagesActions.SET_MESSAGE,
  payload
});
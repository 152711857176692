import createAction from "../../../lib/reducer.utils";
import { UserModel } from './user.reducer';

export const UserActionTypes = {
    SET_USER: "user/SET_USER",
    GET_USER: "user/GET_USER",
    SET_TOKEN: "user/SET_TOKEN",
    GET_TOKEN: "user/GET_TOKEN",
    UPDATE_USER: 'user/UPDATE_USER',
    SET_IS_AUTHENTICATED: 'user/SET_IS_AUTHENTICATED',

}

export const setToken = (token: string | null) => createAction({
    type: UserActionTypes.SET_TOKEN,
    payload: token
})

export const setCurrentUser = (user: UserModel | null) => createAction({
    type: UserActionTypes.SET_USER,
    payload: user
})

export const updateUser = (payload: any) => createAction({
    type: UserActionTypes.UPDATE_USER,
    payload: payload
})

export const setIsAuthenticated = (payload: boolean) => createAction({
    type: UserActionTypes.SET_IS_AUTHENTICATED,
    payload
})
import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

interface Site {
  name: string;
  url?: string;
}

interface ComponentProps {
  sites: Array<Site>;
}

export default function SiteBreadCrumb({ sites = [] } : ComponentProps) {
  return (
    <div role="presentation">
      <Breadcrumbs aria-label="breadcrumb">
        {
          sites && sites.length > 0 && sites.map((site: Site, i: number) => {
            return <Link
              key={site + " " + i}
              underline="hover"
              color="inherit"
              href={site.url}
            >
              <Typography variant="h5">{site.name}</Typography>
            </Link>
          })
        }
      </Breadcrumbs>
    </div>
  );
}
import {RootReducerState} from "../../../root-reducer";

export const isImageModified = (state: RootReducerState): boolean => {
    if (state.imageManagement.isUploading) {
        return true;
    }
    if (state.imageManagement.file) {
        return true;
    }

    return false;
}
export const isImageInitialized = (state: RootReducerState): boolean => state.imageManagement.isInitialized;

export const getImageManagementUploadedFile = (state: RootReducerState) => state.imageManagement.file;
export const getImageManagementUploadedFiles = (state: RootReducerState) => state.imageManagement.files;
export const getUploadedImageURL = (state: RootReducerState) => state.imageManagement.url;
export const getUploadedImageURLs = (state: RootReducerState) => state.imageManagement.urls;
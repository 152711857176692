import {UserModel} from "../../store/reducers/user/user.reducer";
import SiteBreadCrumb from "../../components/page-breadcrumb/page-breadcrumb.component";
import Typography from "@mui/material/Typography";
import PostsTable from "../../components/tables/posts-table.component";
import React, {useEffect, useState} from "react";
import {EventModel, PostModel} from "../../../../models";
import {getEvents, getNewsletter, getPosts, uploadNewEvent, uploadNewsletter} from "../../lib/Requests";
import EventsTable from "../../components/tables/events-table.component";
import {useDispatch, useSelector} from "react-redux";
import {getEventData} from "../../store/reducers/events/events.selectors";
import TextInputField from "../../components/input/text-input-field.component";
import ActionButton from "../../components/button/button.component";
import {
    clearEventContent,
    setEventContent,
    setEventDate,
    setEventPermament, setEventPlace,
    setEventTitle
} from "../../store/reducers/events/events.actions";
import {setMessage} from "../../store/reducers/messages/messages.actions";
import {MessageTypesEnum} from "../../enums/message-types.enum";
import {setNewsLetterEmail} from "../../store/reducers/newsletter/newsletter.actions";
import {Checkbox, ListItemButton, Stack} from "@mui/material";
import DateInputField from "../../components/input/date-input-field.component";
import TextSelectDropdownField from "../../components/input/text-select-dropdown-field.component";
import {
    getImageManagementUploadedFile
} from "../../store/reducers/image-management/selectors/image-management.selectors";
import SingleImageUpload from "../../components/input/single-image-upload.component";
import Service from "../../components/service/service.component";

import EventImage from "../../assets/event.png";

interface ComponentProps
{
    user: UserModel;
}

const Sites = [
    {
        name: "Főoldal",
        url: "/"
    },
    {
        name: "Események",
        url: "/esemenyek"
    }
]



export default function EventsPage({ user } : ComponentProps) {
    const [events, setEvents] = useState<EventModel[]>([]);

    useEffect(() => {
        const collect = async () => {
            const response = await getEvents();
            setEvents(response);
        }

        collect()
    }, []);


    return <div>
        <SiteBreadCrumb sites={Sites} />

        <Typography variant='h3'>Események</Typography>
        <Stack direction="row" spacing={2} flexWrap={"wrap"}>
            <Service title={"Esemény létrehozása"} image={EventImage} url={"/esemenyek/uj-esemeny"} textColor={"#fff"} backgroundColor={"#000"} />
            <Service title={"Meglévő események"} image={EventImage} url={"/esemenyek/lista"} textColor={"#fff"} backgroundColor={"#000"} />
        </Stack>
    </div>
}
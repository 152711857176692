import {Stack, TextField} from "@mui/material";

interface ComponentProps {
    title?: string;
    variant?: string | "ORANGE" | "GRAY";
    value?: any;
    onChange?: any;
    type?: any;
    name?: string;
    InputProps?: any;
    readOnly?: boolean;
    languageSelector?: boolean;
    textbox?: boolean;
}

function returnStyles(variant: string) {
    const styles = {
        color: "#111",
        border: "none",
        fontSize: "1.2em",
        outline: "none",
        textDecoration: "none",
        margin: "0.5rem 0",
        backgroundColor: "#fff",
    }

    switch (variant) {
        case "GRAY":
            styles.backgroundColor = "#F9F9F9";
            break;
        case "ORANGE":
            styles.backgroundColor = "#F86F03";
            styles.color = "#fff";
            break;
    }

    return styles;
}

export default function TextInputField({ title, variant = "", value = "", onChange, textbox, type, name, InputProps = {}, readOnly } : ComponentProps)
{
    const analyzeValue = (value: any = "") => {
        if (`${value}`.toString() === "false") {
            return "No";
        }

        if (`${value}`.toString() === "true") {
            return "Yes";
        }

        return value;
    }

    return <TextField
        id="filled-hidden-label-small"
        label={title}
        variant="outlined"
        fullWidth
        value={analyzeValue(value)}
        name={name ? name : ""}
        onChange={onChange}
        type={type ? type : "text"}
        InputProps={InputProps}
        sx={returnStyles(variant)}
        disabled={!!readOnly}
        multiline={textbox}
        rows={textbox ? 8 : 1}
    />
}
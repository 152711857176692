import { Stack, Typography } from "@mui/material";
import {useDropzone} from "react-dropzone";
import {ImageModel} from "./models";
import {MyDropZone} from "./styles";
import {useDispatch, useSelector} from "react-redux";
import {setMessage} from "../../store/reducers/messages/messages.actions";
import {MessageTypesEnum} from "../../enums/message-types.enum";
import {uploadImageManagementFile} from "../../store/reducers/image-management/actions/image-management.actions";
import {
    getImageManagementUploadedFile, getUploadedImageURL, getUploadedImageURLs, isImageInitialized
} from "../../store/reducers/image-management/selectors/image-management.selectors";

export default function SingleImageUpload() {
    const dispatch = useDispatch();

    const isInitialized = useSelector(isImageInitialized);
    const uploadedImageURL = useSelector(getUploadedImageURL);
    const getUploadedImage = useSelector(getImageManagementUploadedFile);
    const imageSrc: string | ArrayBuffer = isInitialized ? uploadedImageURL : (getUploadedImage?.base64 ? getUploadedImage?.base64 : "");

    const onDrop = async (acceptedFiles: File[]) => {
        try {
            const file = acceptedFiles[0];

            return new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.onload = () => {
                    const fileAsBase64 = reader.result;
                    const uploadingImage: ImageModel = {
                        base64: fileAsBase64,
                        deleted: false,
                        file: file,
                        modified: true,
                        isChecked: true,
                        url: ""
                    };

                    resolve(uploadingImage);
                };

                reader.onerror = reject;

                reader.readAsDataURL(file);
            }).then((convertedFile: any) => {
                dispatch(uploadImageManagementFile(convertedFile));
            });
        } catch (error: any) {
            dispatch(
                setMessage({
                    message: "Image upload failed :(",
                    type: MessageTypesEnum.ERROR
                })
            )
        }
    }
    const {getRootProps, getInputProps} = useDropzone({onDrop});

    return (
        <Stack flexDirection="row" flexWrap={"wrap"} sx={{ width: "100%" }} gap={2}>
            <div>
                <img
                    src={`${imageSrc}`}
                    alt="Még nem töltött fel egy képet sem!"
                    width={300}
                    height={300}
                    style={{objectFit: "cover"}}
                />
            </div>

            <MyDropZone {...getRootProps()}>
                <input {...getInputProps()} />
                <Typography variant="body1">
                    Drag & drop here your files!
                </Typography>
            </MyDropZone>
        </Stack>
    );
}
import { combineReducers } from "redux";
import {UserModel, userReducer} from "./reducers/user/user.reducer";
import { messagesReducer } from './reducers/messages/messages.reducer';
import {newsletterReducer} from "./reducers/newsletter/newsletter.reducer";
import {eventsReducer} from "./reducers/events/events.reducer";
import {MessageModel} from "../models/message.model";
import {EventModel, NewsletterModel} from "../../../models";
import {imageManagementReducer} from "./reducers/image-management/image-management.reducer";
import {ImageManagementModel} from "./reducers/image-management/models";
import {postReducer, PostReducerModel} from "./reducers/posts/post.reducer";

export interface RootReducerState
{
    user: UserModel,
    error: MessageModel,
    newsletter: NewsletterModel,
    event: EventModel,
    imageManagement: ImageManagementModel;
    post: PostReducerModel;
}

export const rootReducer = combineReducers({
    user: userReducer,
    error: messagesReducer,
    newsletter: newsletterReducer,
    event: eventsReducer,
    imageManagement: imageManagementReducer,
    post: postReducer
});
import './main-layout.styles.css';

import * as React from 'react';
import Box from '@mui/material/Box';
import { Outlet, useNavigate } from 'react-router-dom';
import Header from '../header/header.component';
import { MainLayoutBox } from './main-layout.styled';

export default function MainLayout() {
  return (
    <MainLayoutBox>
      <Header />

      <Box component='main' sx={{ flexGrow: 1, p: 5, overflow: "scroll", height: "100vh" }} className='main'>
        <Outlet />
      </Box>
    </MainLayoutBox>
  );
}
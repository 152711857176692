import {ImageManagementModel} from "./models";
import {ReducerActionInterface} from "../../../lib/reducer.utils";
import {ImageManagementActionTypes} from "./actions/image-management.actions";
import {ImageModel} from "../../../components/input/models";

const INITIAL_STATE: ImageManagementModel = {
    isInitialized: false, // If I want to load urls instead of files
    url: "",
    urls: [],
    file: null,
    files: null,
    isUploading: false
};

export const imageManagementReducer = (state = INITIAL_STATE, action: ReducerActionInterface) => {
    const {type, payload} = action;

    switch (type) {
        case ImageManagementActionTypes.INITIALIZE:
            const { url, urls } = payload;

            return {
                ...state,
                isInitialized: true,
                url,
                urls
            };
        case ImageManagementActionTypes.CLEAR_CONTENT:
            return INITIAL_STATE;
        case ImageManagementActionTypes.UPLOAD_FILE:
            return {
                ...state,
                file: payload,
                isUploading: true,
                isInitialized: false
            };
        case ImageManagementActionTypes.UPLOAD_FILES:
            return {
                ...state,
                files: payload,
                isUploading: true,
                isInitialized: false
            };

        case ImageManagementActionTypes.REMOVE_IMAGE:
            const { type, base64 } = payload;

            if (type === "file") {
                return {
                    ...state,
                    file: null
                };
            }

            if (type === "files") {
                return {
                    ...state,
                    files: state.files?.filter((file: ImageModel) => file.base64 !== base64)
                };
            }
            break;

        default:
            return state;
    }
}
import Catalog from '../../components/catalog/catalog.component';
import NavBar from '../../components/nav-bar/nav-bar.component';
import { UserModel } from '../../store/reducers/user/user.reducer';
import {Stack} from "@mui/material";
import Service from "../../components/service/service.component";

import EventImage from "../../assets/event.png";
import PostImage from "../../assets/instagram-post.png";
import MessagesImage from "../../assets/messages.png";
import NewsletterImage from "../../assets/newsletter.png";
import SettingsImage from "../../assets/settings.png";

interface ComponentProps {
  user: UserModel;
}

export default function Home({ user }: ComponentProps) {
  return <div>
    <NavBar />
    <Stack flexDirection={"row"} flexWrap={"wrap"} gap={5} justifyContent={"flex-start"} alignItems={"flex-start"}>
      <Service title={"Események"} image={EventImage} url={"/esemenyek"} textColor={"#fff"} backgroundColor={"#000"} />
      <Service title={"Posztok"} image={PostImage} url={"/posztok"} textColor={"#fff"} backgroundColor={"#000"} />
      <Service title={"Üzenetek"} image={MessagesImage} url={"/uzenetek"} textColor={"#fff"} backgroundColor={"#000"} />
      <Service title={"Hírlevél"} image={NewsletterImage} url={"/hirlevel"} textColor={"#fff"} backgroundColor={"#000"} />
      <Service title={"Beállítások"} image={SettingsImage} url={"/beallitasok"} textColor={"#fff"} backgroundColor={"#000"} />
    </Stack>
  </div>;
}
import createAction from "../../../../lib/reducer.utils";

export const ImageManagementActionTypes = {
    "UPLOAD_FILE": "image-management/UPLOAD_FILE",
    "UPLOAD_FILES": "image-management/UPLOAD_FILES",
    "CLEAR_CONTENT": "image-management/CLEAR_CONTENT",
    "SET_IMAGE_MANAGEMENT_URL": "image-management/SET_IMAGE_MANAGEMENT_URL",
    "SET_IMAGE_MANAGEMENT_URLS": "image-management/SET_IMAGE_MANAGEMENT_URLS",
    "INITIALIZE": "image-management/INITIALIZE",
    "REMOVE_IMAGE": "image-management/REMOVE_IMAGE"

}

export const removeImageFromManagement = (type: "file" | "files", base64: string | ArrayBuffer | null) => createAction({
    type: ImageManagementActionTypes.REMOVE_IMAGE,
    payload: { type, base64}
})

export const imageManagementClearContent = () => createAction({
    type: ImageManagementActionTypes.CLEAR_CONTENT
})

export const imageManagementInitialize = (url: string, urls?: string[]) => createAction({
    type: ImageManagementActionTypes.INITIALIZE,
    payload: { url, urls: urls ? urls : [] }
})

export const uploadImageManagementFile = (file: File | ArrayBuffer | null) => createAction({
    type: ImageManagementActionTypes.UPLOAD_FILE,
    payload: file,
})

export const uploadImageManagementFiles = (files: File[] | ArrayBuffer[] | null) => createAction({
    type: ImageManagementActionTypes.UPLOAD_FILES,
    payload: files,
})
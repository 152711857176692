import { MessageModel } from '../../../models/message.model';
import { ReducerActionInterface } from '../../../lib/reducer.utils';
import { MessagesActions } from './messages.actions';
import { MessageTypesEnum } from '../../../enums/message-types.enum';
import { toast } from 'react-toastify';

const INTIAL_STATE: MessageModel = {
  message: '',
  type: MessageTypesEnum.INFO
}

export const messagesReducer = (state = INTIAL_STATE, action: ReducerActionInterface) => {
  const { type, payload } = action;

  switch (type) {
    case MessagesActions.SET_MESSAGE:
      switch (payload.type) {
        case MessageTypesEnum.ERROR:
          toast.error(payload.message);
          break;
        case MessageTypesEnum.WARNING:
          toast.warning(payload.message);
          break;
        case MessageTypesEnum.SUCCESS:
          toast.success(payload.message);
          break;
        case MessageTypesEnum.INFO:
          toast.info(payload.message);
          break;
      }

      return {
        message: payload.message,
        type: payload.type
      }
    default:
      return state;
  }
}
import createAction from "../../../lib/reducer.utils";
import {PostActionTypes} from "../posts/post.actions";
import {EventModel} from "../../../../../models";

export const EventsActionTypes = {
    "SET_TITLE": "events/SET_TITLE",
    "SET_PERMAMENT": "events/SET_PERMAMENT",
    "SET_DATE": "events/SET_DATE",
    "SET_CONTENT": "events/SET_CONTENT",
    "SET_PLACE": "events/SET_PLACE",
    "CLEAR_CONTENT": "events/CLEAR_CONTENT",
    "INIT_EVENT": "events/INIT_EVENT"
}

export const initEvent = (payload: EventModel) => createAction({
    type: EventsActionTypes.INIT_EVENT,
    payload: payload
})

export const clearEventContent = () => createAction({
    type: EventsActionTypes.CLEAR_CONTENT
})

export const setEventTitle = (payload: string) =>  createAction({
    type: EventsActionTypes.SET_TITLE,
    payload: payload
})

export const setEventPermament = (payload: boolean) =>  createAction({
    type: EventsActionTypes.SET_PERMAMENT,
    payload: payload
})

export const setEventDate = (payload: string) =>  createAction({
    type: EventsActionTypes.SET_DATE,
    payload: payload
})

export const setEventContent = (payload: string) =>  createAction({
    type: EventsActionTypes.SET_CONTENT,
    payload: payload
})

export const setEventPlace = (payload: string) =>  createAction({
    type: EventsActionTypes.SET_PLACE,
    payload: payload
})
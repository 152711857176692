import {ImageListItem, ImageListItemBar, Stack, Typography} from "@mui/material";
import {useDropzone} from "react-dropzone";
import {useDispatch, useSelector} from "react-redux";
import {
    getImageManagementUploadedFiles,
    getUploadedImageURLs,
    isImageInitialized
} from "../../store/reducers/image-management/selectors/image-management.selectors";
import {ImageModel} from "./models";
import {
    removeImageFromManagement,
    uploadImageManagementFiles
} from "../../store/reducers/image-management/actions/image-management.actions";
import {setMessage} from "../../store/reducers/messages/messages.actions";
import {MessageTypesEnum} from "../../enums/message-types.enum";
import {MyDropZone} from "./styles";
import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {removeImage} from "../../lib/Requests";
import {getUser} from "../../store/reducers/user/user.selector";

export default function MultipleImageUpload() {
    const dispatch = useDispatch();

    const isInitialized = useSelector(isImageInitialized);
    const getUploadedURLs = useSelector(getUploadedImageURLs);
    const getUploadedImages = useSelector(getImageManagementUploadedFiles);
    const currentUser = useSelector(getUser);

    const onDrop = async (acceptedFiles: File[]) => {
        try {
            Promise.all(
                acceptedFiles.map((file) => {
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader();

                        reader.onload = () => {
                            const fileAsBase64 = reader.result;
                            const uploadingImage: ImageModel = {
                                base64: fileAsBase64,
                                deleted: false,
                                file: file,
                                modified: true,
                                isChecked: true,
                                url: ""
                            };

                            resolve(uploadingImage);
                        };

                        reader.onerror = reject;

                        reader.readAsDataURL(file);
                    });
                })
            ).then((convertedFiles: any[]) => {
                dispatch(uploadImageManagementFiles(convertedFiles));
            });
        } catch (error: any) {
            dispatch(
                setMessage({
                    message: "Image uploads failed :(",
                    type: MessageTypesEnum.ERROR
                })
            )
        }
    }
    const {getRootProps, getInputProps} = useDropzone({onDrop});

    const handleUploadedDelete = async (url: string) => {
        try {
            await removeImage(currentUser.access_token, url);

            dispatch(setMessage({
                type: MessageTypesEnum.SUCCESS,
                message: "A kép sikeresen törölve!"
            }))
        } catch (e: any) {
            dispatch(setMessage({
                type: MessageTypesEnum.ERROR,
                message: "A kép törlése sikertelen!"
            }))
        }
    }
    const handleBase64Delete = (base64: string | ArrayBuffer | null) => dispatch(removeImageFromManagement("files", base64));

    return (
        <>
            <br />
            <MyDropZone {...getRootProps()}>
                <input {...getInputProps()} />
                <Typography variant="body1">
                    Kérlek húzd ide vagy kattints ide a kepek feltöltéséhez!
                </Typography>
            </MyDropZone>
            <br />
            <br />

            <Stack flexDirection="row" flexWrap={"wrap"} sx={{width: "100%", minHeight: 450, height: "auto", transform: 'translateZ(0)'}} gap={1}>
                {
                    isInitialized && getUploadedURLs.length > 0 && getUploadedURLs.map((url: string, i) => {
                        return (
                        <ImageListItem key={url + " " + i} cols={3}>
                            <img
                                src={url}
                                alt={"image"}
                                style={{objectFit: "cover", width: "300px", height: "300px"}}
                                loading={"lazy"}
                            />
                            <ImageListItemBar
                                onClick={() => handleUploadedDelete(url)}
                                sx={{
                                    background:
                                        'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                        'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                                }}
                                title={"Poszt kép"}
                                position="top"
                                actionIcon={
                                    <IconButton
                                        sx={{ color: 'white', width: 50, height: 50 }}
                                        aria-label={`delete`}
                                    >
                                        <DeleteForeverIcon />
                                    </IconButton>
                                }
                                actionPosition="left"
                            />
                        </ImageListItem>
                        );
                    })}

                {
                    !isInitialized && getUploadedImages !== null && getUploadedImages.map((image: ImageModel, i) => {
                    const imageSrc: string = `${image?.modified ? image?.base64 : image?.url}`;

                    return (
                        <ImageListItem key={image + " " + i} cols={3}>
                            <img
                                src={imageSrc}
                                alt={"image"}
                                width={300}
                                height={300}
                                style={{objectFit: "cover", width: "300px", height: "300px"}}
                                loading={"lazy"}
                            />
                            <ImageListItemBar
                                onClick={() => handleBase64Delete(image.base64)}
                                sx={{
                                    background:
                                        'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                        'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                                }}
                                title={"Poszt kép"}
                                position="top"
                                actionIcon={
                                    <IconButton
                                        sx={{ color: 'white', width: 50, height: 50 }}
                                        aria-label={`delete`}
                                    >
                                        <DeleteForeverIcon />
                                    </IconButton>
                                }
                                actionPosition="left"
                            />
                        </ImageListItem>
                    );
                })}
            </Stack>
        </>
    );
}
import {DataGrid, GridColDef, GridEventListener, huHU} from "@mui/x-data-grid";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide, Stack, Tooltip} from "@mui/material";
import {TableIcon} from "./posts-table.component";
import VisibilityIcon from '@mui/icons-material/Visibility';
import moment from "moment";
import {ContactModel} from "../../../../models/contact.model";
import React, {useState} from "react";
import {useDispatch} from "react-redux";
import TextInputField from "../input/text-input-field.component";
import {TransitionProps} from "@mui/material/transitions";

interface ComponentProps {
    entries: ContactModel[];
}


const OPERATION_CELL_WIDTH = 70;

const columns: GridColDef[] = [
    { field: 'id', headerName: 'Id', width: 70 },
    {
        field: 'see', headerName: '', width: OPERATION_CELL_WIDTH, renderCell: (params) => {
            return (
                <Stack flexDirection="row">
                    <TableIcon>
                        <Tooltip title={"Open"}>
                            <VisibilityIcon />
                        </Tooltip>
                    </TableIcon>
                </Stack>
            );
        },
    },
    { field: 'sentAt', headerName: 'Dátum', width: 200 },
    { field: 'name', headerName: 'Név', width: 200 },
    { field: 'email', headerName: 'E-mail cím', width: 200 },
    { field: 'message', headerName: 'Üzenet', width: 400 },
];


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function MessagesTable({ entries } : ComponentProps) {
    const [open, setOpen] = useState<boolean>(false);
    const [value, setValue] = useState<string>("");
    const dispatch = useDispatch();

    const handleTableDataClick: GridEventListener<'cellClick'> = (params, event, details) =>
    {
        const { field, row } = params;

        if (field === 'see') {
            setValue(row.message);
            setOpen(true);
        }
    };

    return <div className='table'>
        <DataGrid
            className="table"
            rows={entries.map((entry: ContactModel, i: number) => ({
                ...entry,
                id: i + 1,
                sentAt: entry?.sentAt ? moment(entry.sentAt).format("YYYY.MM.DD HH:mm") : ""
            }))}
            sx={{ height: 600, width: "100%" }}
            columns={columns}
            initialState={{
                pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                },
            }}
            onCellClick={handleTableDataClick}
            pageSizeOptions={[5, 10]}
            localeText={huHU.components.MuiDataGrid.defaultProps.localeText}
        />

        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setOpen(!open)}
            aria-describedby="alert-dialog-slide-description"
            sx={{
                "& .MuiDialog-paper": {
                    maxWidth: "500px",
                    width: "100%",
                    minHeight: "400px"
                }
            }}
        >
            <DialogTitle>{"Beállítás szerkesztése"}</DialogTitle>
            <DialogContent>
                <TextInputField title={"Tartalom"} value={value} onChange={(e: any) => setValue(e.target.value)} textbox />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    setOpen(!open);
                    setValue("");
                }}>Bezárás</Button>
            </DialogActions>
        </Dialog>
    </div>;
}
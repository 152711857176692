import Typography from '@mui/material/Typography';
import { Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getToken, getUser, isAuthenticated } from '../store/reducers/user/user.selector';
import { useEffect, useState } from 'react';
import { setCurrentUser, setIsAuthenticated, setToken, updateUser } from '../store/reducers/user/user.actions';
import { UserModel } from '../store/reducers/user/user.reducer';
import sendApiRequest, { NetworkMethods, ResponseProps } from '../lib/Network';

function displayError(message: string) {
  return <div style={{ textAlign: 'center' }}>
    <Typography variant='h4' color='red'>{message}</Typography>
    <a href='/bejelentkezes'><Typography variant='h4' color='blue'>Bejelentkezés</Typography></a>
  </div>;
}

interface ComponentProps {
  user: UserModel | null;
}


export const isValid = async (token: string): Promise<UserModel | null> => {
  try {
    const response = await sendApiRequest({
      method: NetworkMethods.POST,
      endpoint: 'user/loggedin',
      headers: {
        "x-public-key": process.env.REACT_APP_PUBLIC_REQUEST_KEY,
      },
      payload: {
        token: token
      }
    });

    return response?.data;
  } catch (e) {
    return null;
  }
};

export default function RequiredAuth({ user }: ComponentProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isSecuredConnection = useSelector(isAuthenticated);

  useEffect(() => {
    if (!user?.access_token) {
      dispatch(setCurrentUser(null));
      dispatch(setToken(null));
      dispatch(setIsAuthenticated(false));
    }

    const userChecker = async () => {
      const isValidUser = await isValid(`${user?.access_token}`);

      if (!isValidUser) {
        navigate('/bejelentkezes');
        dispatch(setCurrentUser(null));
        dispatch(setToken(null));
        dispatch(setIsAuthenticated(false));
      }
      else
      {
        dispatch(updateUser(isValidUser));
        dispatch(setIsAuthenticated(true));
      }
    };

    userChecker();
  }, []);

  const navigateToLogin = () => navigate('/bejelentkezes');

  if (!isSecuredConnection) {
    navigateToLogin();

    /* Basically, this is unnecessary but to keep react rules in mind we have to keep this line. */
    return displayError('Hiba! Bejelentkezés szükséges!');
  }

  return <Outlet />;
}
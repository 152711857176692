import { Autocomplete, createFilterOptions, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';

interface ComponentProps
{
  data: TextSelectListDataModel[];
  title: string;
  value: any;
  onChange: (prop: any) => any;
}

export interface TextSelectListDataModel
{
  title: string;
  value: any;
}

/*
*
* The given data list must contain a title or name property.
*
* */
export default function TextSelectDropdownField({ data, title, value, onChange } : ComponentProps)
{
  return <FormControl fullWidth sx={{ margin: "0.5rem 0", backgroundColor: "#fff", color: "#111" }}>
    <InputLabel id="text-dropdown-select-label-id">{title}</InputLabel>
    <Select
      labelId="text-dropdown-select-label-id"
      id="text-dropdown-select"
      value={value}
      label={title}
      onChange={onChange}
    >
      { data.map((e: TextSelectListDataModel, i: number) => <MenuItem key={e + " " + i} value={e.value}>{e.title}</MenuItem>) }
    </Select>
  </FormControl>
}
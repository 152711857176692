import {EventModel, UserModel} from "../../../../../models";
import SiteBreadCrumb from "../../../components/page-breadcrumb/page-breadcrumb.component";
import Typography from "@mui/material/Typography";
import SingleImageUpload from "../../../components/input/single-image-upload.component";
import TextInputField from "../../../components/input/text-input-field.component";
import TextSelectDropdownField from "../../../components/input/text-select-dropdown-field.component";
import DateInputField from "../../../components/input/date-input-field.component";
import {Checkbox, ListItemButton} from "@mui/material";
import ActionButton from "../../../components/button/button.component";
import React, {useEffect} from "react";
import {
    clearEventContent,
    setEventDate,
    setEventPermament,
    setEventPlace,
    setEventTitle
} from "../../../store/reducers/events/events.actions";
import {uploadNewEvent} from "../../../lib/Requests";
import {setMessage} from "../../../store/reducers/messages/messages.actions";
import {MessageTypesEnum} from "../../../enums/message-types.enum";
import {useDispatch, useSelector} from "react-redux";
import {
    getImageManagementUploadedFile
} from "../../../store/reducers/image-management/selectors/image-management.selectors";
import {getEventData} from "../../../store/reducers/events/events.selectors";
import {imageManagementClearContent} from "../../../store/reducers/image-management/actions/image-management.actions";
import {getUser} from "../../../store/reducers/user/user.selector";

const Sites = [
    {
        name: "Főoldal",
        url: "/"
    },
    {
        name: "Események",
        url: "/esemenyek"
    },
    {
        name: "Új esemény",
        url: "/esemenyek/uj-esemeny"
    }
]

const defaultPlaces = [
    {
        title: 'Kőszegszerdahely',
        value: 'Kőszegszerdahely',
    },
    {
        title: 'Bozsok',
        value: 'Bozsok',
    },
    {
        title: 'Velem',
        value: 'Velem',
    },
    {
        title: 'Kőszegdoroszló',
        value: 'Kőszegdoroszló',
    },
    {
        title: 'Szombathely',
        value: 'Szombathely',
    },
]

export default function CreateEventPage({ user } : {  user: UserModel})
{
    const dispatch = useDispatch();

    const previewImage = useSelector(getImageManagementUploadedFile);
    const currentUser = useSelector(getUser);
    const eventData: EventModel = useSelector(getEventData);
    const { title, content, permament, date, place } = eventData;

    useEffect(() => {
        dispatch(imageManagementClearContent());
    }, []);

    const onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => dispatch(setEventTitle(e.target.value));
    const onPermamentChange = (status: boolean) => dispatch(setEventPermament(status));
    const onDateChange = (e: any) => dispatch(setEventDate(e['_d']));
    const onPlaceChange = (e: React.ChangeEvent<HTMLInputElement>) => dispatch(setEventPlace(e.target.value));

    const onSubmit = async () => {
        try {
            const formData = new FormData();

            formData.append("title", title);
            formData.append("permament", `${permament}`);
            formData.append("date", date.toISOString());
            formData.append("preview", previewImage?.file as File);
            formData.append("place", place);

            await uploadNewEvent(currentUser.access_token, formData);

            dispatch(setMessage({
                message: `Sikeres feltöltés!`,
                type: MessageTypesEnum.SUCCESS,
            }));

            dispatch(clearEventContent())
            dispatch(imageManagementClearContent())
        } catch (e) {
            dispatch(setMessage({
                message: `A feltöltés sikertelen!`,
                type: MessageTypesEnum.ERROR,
            }));
        }
    }

    return <div>
        <SiteBreadCrumb sites={Sites} />

        <Typography variant='h3'>Esemény létrehozás</Typography>
        <br />
        <br />
        <SingleImageUpload />
        <TextInputField title={'Cím'} value={title} onChange={onTitleChange} />
        <TextSelectDropdownField value={place} onChange={onPlaceChange} data={defaultPlaces}
                                 title={'Helyszín'} />
        <DateInputField title={'Dátum'} value={date} onChange={onDateChange} />
        <ListItemButton onClick={() => onPermamentChange(!permament)}>
            <Checkbox
                checked={permament}
                inputProps={{'aria-label': 'controlled'}}
            />
            <Typography variant='body1'>Állandó-e ?</Typography>
        </ListItemButton>
        <ActionButton title={'Hozzáadás'} type='SAVE' action={onSubmit}  />
        <br/>
        <br/>
    </div>
}
import {ProductModel} from "../../models/products.model";
import {DataGrid, GridColDef, GridEventListener, huHU} from "@mui/x-data-grid";
import {PostModel} from "../../../../models";
import moment from "moment";
import EditIcon from '@mui/icons-material/Edit';
import {Stack, Tooltip} from "@mui/material";
import {styled} from "@mui/material/styles";
import {useState} from "react";
import PostEditMenu from "../../routes/posts/edit/post-edit.component";
import {useNavigate} from "react-router-dom";

interface ComponentProps {
    posts: PostModel[];
}

export const TableIcon = styled("b")(({ theme }) => ({
    backgroundColor: "blueviolet",
    padding: "0.5em",
    cursor: "pointer",
    color: "#fff",
    "&:hover": {
        border: "1px solid #fff"
    }
}));

const OPERATION_CELL_WIDTH = 70;

const columns: GridColDef[] = [
    { field: 'id', headerName: 'Id', width: 70 },
    { field: 'title', headerName: 'Cím', width: 200 },
    { field: 'slugTitle', headerName: 'Generált cím', width: 450 },
    { field: 'date', headerName: 'Dátum', width: 200 },
    { field: 'content', headerName: 'Tartalom', width: 200 },
    {
        field: 'edit', headerName: '', width: OPERATION_CELL_WIDTH, renderCell: (params) => {
            return (
                <Stack flexDirection="row">
                    <TableIcon>
                        <Tooltip title={"Szerkesztés"}>
                            <EditIcon />
                        </Tooltip>
                    </TableIcon>
                </Stack>
            );
        },
    },
];

export default function PostsTable({ posts } : ComponentProps) {
    const navigate = useNavigate();

    const handleTableDataClick: GridEventListener<'cellClick'> = (params, event, details) =>
    {
        const { field, row } = params;

        if (field === 'edit') {
            navigate(`/posztok/szerkesztes/${row.dbId}`);
        }
    };

    return <div className='table'>
        <DataGrid
            className="table"
            rows={posts.map((post: PostModel, i: number) => ({
                ...post,
                id: i + 1,
                dbId: post.id,
                date: moment(post.date).format("YYYY.MM.DD HH:mm")
            }))}
            onCellClick={handleTableDataClick}
            sx={{ height: 600, width: "100%" }}
            columns={columns}
            initialState={{
                pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                },
            }}
            pageSizeOptions={[5, 10]}
            localeText={huHU.components.MuiDataGrid.defaultProps.localeText}
        />
    </div>;
}
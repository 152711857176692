import createAction from "../../../lib/reducer.utils";
import {PostModel} from "../../../../../models";

export const PostActionTypes = {
    "SET_TITLE": "posts/SET_TITLE",
    "SET_DATE": "post/SET_DATE",
    "SET_CONTENT": "post/SET_CONTENT",
    "SET_AUTHOR_ID": "post/SET_AUTHOR_ID",
    CLEAR_CONTENT: "post/CLEAR_CONTENT",
    INIT: "post/INIT",
    SET_MODIFIED: "post/SET_MODIFIED",

}

export const setPostModified = (payload: boolean) => createAction({
    type: PostActionTypes.SET_MODIFIED,
    payload
})

export const initPost = (payload: PostModel) => createAction({
    type: PostActionTypes.INIT,
    payload
})

export const clearPostContent = () => createAction({
    type: PostActionTypes.CLEAR_CONTENT
})

export const setPostTitle = (payload: string) => createAction({
    type: PostActionTypes.SET_TITLE,
    payload
})
export const setPostDate = (payload: Date) => createAction({
    type: PostActionTypes.SET_DATE,
    payload
})
export const setPostContent = (payload: string) => createAction({
    type: PostActionTypes.SET_CONTENT,
    payload
})
export const setAuthorId = (payload: number) => createAction({
    type: PostActionTypes.SET_AUTHOR_ID,
    payload
})
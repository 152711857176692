import {UserModel} from "../../store/reducers/user/user.reducer";
import SiteBreadCrumb from "../../components/page-breadcrumb/page-breadcrumb.component";
import Typography from "@mui/material/Typography";
import PostsTable from "../../components/tables/posts-table.component";
import {useEffect, useState} from "react";
import {PostModel} from "../../../../models";
import {getPosts, getSiteSettings} from "../../lib/Requests";
import {SettingsModel, SettingsResponseModel} from "../../../../models/settings.model";
import SettingsTable from "../../components/tables/settings-table.component";

interface ComponentProps
{
    user: UserModel;
}

const Sites = [
    {
        name: "Főoldal",
        url: "/"
    },
    {
        name: "Beállítások",
        url: "/beallitasok"
    }
]

export default function SettingsPage({ user } : ComponentProps) {
    const [settings, setSettings] = useState<SettingsResponseModel[]>([]);

    useEffect(() => {
        const collect = async () => {
            const response = await getSiteSettings();
            setSettings(response);
        }

        collect()
    }, []);

    return <div>
        <SiteBreadCrumb sites={Sites} />

        <Typography variant='h3'>Beállítások</Typography>

        <SettingsTable settings={settings} />
    </div>
}
import React from 'react';
import {useSelector} from "react-redux";
import {getUser} from "./store/reducers/user/user.selector";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import RequiredAuth from "./security/required-auth";
import SignIn from "./routes/signin/sign-in.route";
import Home from "./routes/home/home.route";
import MainLayout from "./components/layout/main-layout.component";
import SignOut from './routes/sign-out/sign-out.component';
import PostPage from "./routes/posts/posts.route";
import EventsPage from "./routes/events/events.route";
import SettingsPage from "./routes/settings/settings.route";
import NewsletterPage from "./routes/newsletter/newsletter.route";
import MessagesPage from "./routes/messages/messages.route";
import CreateEventPage from "./routes/events/create/events-create.route";
import EventsListPage from "./routes/events/list/events-list.route";
import CreatePostPage from "./routes/posts/create/posts-create.route";
import PostListPage from "./routes/posts/list/post-list.route";
import PostEditPage from "./routes/posts/edit/post-edit.component";
import EditEventPage from "./routes/events/edit/events-edit.route";

function App() {
    const currentUser = useSelector(getUser);

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<MainLayout />}>
                    <Route element={<RequiredAuth user={currentUser} />}>
                        <Route index element={<Home user={currentUser} />}/>
                        <Route path="posztok">
                            <Route index element={<PostPage />} />
                            <Route path={"lista"} element={<PostListPage user={currentUser} />} />
                            <Route path={"uj-poszt"} element={<CreatePostPage user={currentUser} />} />
                            <Route path={"szerkesztes/:id"} element={<PostEditPage user={currentUser} />} />
                        </Route>
                        <Route path="uzenetek" element={<MessagesPage user={currentUser} />}/>
                        <Route path="hirlevel" element={<NewsletterPage user={currentUser} />}/>
                        <Route path="esemenyek">
                            <Route index element={<EventsPage user={currentUser} />} />
                            <Route path={"lista"} element={<EventsListPage user={currentUser} />} />
                            <Route path={"uj-esemeny"} element={<CreateEventPage user={currentUser} />} />
                            <Route path={"szerkesztes/:id"} element={<EditEventPage user={currentUser} />} />
                        </Route>
                        <Route path="beallitasok" element={<SettingsPage user={currentUser} />}/>
                        <Route path="kijelentkezes" element={<SignOut />}/>
                    </Route>
                </Route>
                <Route path="/bejelentkezes" element={<SignIn />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;

import {ProductModel} from "../../models/products.model";
import {DataGrid, GridColDef, GridEventListener, huHU} from "@mui/x-data-grid";
import {EventModel, PostModel} from "../../../../models";
import moment from "moment";
import EditIcon from '@mui/icons-material/Edit';
import {Stack, Tooltip} from "@mui/material";
import {styled} from "@mui/material/styles";
import {TableIcon} from "./posts-table.component";
import {useNavigate} from "react-router-dom";

interface ComponentProps {
    events: EventModel[];
}

const OPERATION_CELL_WIDTH = 70;

const columns: GridColDef[] = [
    { field: 'id', headerName: 'Id', width: 70 },
    { field: 'title', headerName: 'Cím', width: 200 },
    { field: 'date', headerName: 'Dátum', width: 200 },
    { field: 'place', headerName: 'Helyszín', width: 200 },
    {
        field: 'edit', headerName: '', width: OPERATION_CELL_WIDTH, renderCell: (params) => {
            return (
                <Stack flexDirection="row">
                    <TableIcon>
                        <Tooltip title={"Szerkesztés"}>
                            <EditIcon />
                        </Tooltip>
                    </TableIcon>
                </Stack>
            );
        },
    },
];

export default function EventsTable({ events } : ComponentProps) {
    const navigate = useNavigate();

    const handleTableDataClick: GridEventListener<'cellClick'> = (params, event, details) =>
    {
        const { field, row } = params;

        if (field === 'edit') {
            navigate(`/esemenyek/szerkesztes/${row.dbId}`);
        }
    };

    return <div className='table'>
        <DataGrid
            className="table"
            rows={events.map((event: EventModel, i: number) => ({
                ...event,
                id: i + 1,
                dbId: event.id,
                date: moment(event.date).format("YYYY.MM.DD HH:mm")
            }))}
            sx={{ height: 600, width: "100%" }}
            onCellClick={handleTableDataClick}
            columns={columns}
            initialState={{
                pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                },
            }}
            pageSizeOptions={[5, 10]}
            localeText={huHU.components.MuiDataGrid.defaultProps.localeText}
        />
    </div>;
}
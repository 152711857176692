import {PostModel, UserModel} from "../../../../../models";
import React, {useEffect, useState} from "react";
import {getPosts} from "../../../lib/Requests";
import SiteBreadCrumb from "../../../components/page-breadcrumb/page-breadcrumb.component";
import Typography from "@mui/material/Typography";
import PostsTable from "../../../components/tables/posts-table.component";

const Sites = [
    {
        name: "Főoldal",
        url: "/"
    },
    {
        name: "Posztok",
        url: "/posztok"
    },
    {
        name: "Lista",
        url: "/posztok/lista"
    }
]

export default function PostListPage({ user } : { user: UserModel }) {
    const [posts, setPosts] = useState<PostModel[]>([]);

    useEffect(() => {
        const collect = async () => {
            const response = await getPosts();
            setPosts(response);
        }

        collect()
    }, []);

    return <div>
        <SiteBreadCrumb sites={Sites} />
        <Typography variant='h3'>Posztok</Typography>
        <PostsTable posts={posts} />
    </div>
}
import {TextField} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/hu';
import moment from 'moment';

interface ComponentProps {
  title?: string;
  variant?: string | "ORANGE" | "GRAY";
  value?: any;
  onChange?: any;
  readOnly?: boolean;
}

function returnStyles(variant: string) {
  const styles = {
    color: "#111",
    border: "none",
    textTransform: "capitalize",
    fontSize: "1.2em",
    outline: "none",
    textDecoration: "none",
    margin: "0.5rem 0",
    backgroundColor: "#fff",
    width: "100%"
  }

  switch (variant) {
    case "ORANGE":
      styles.backgroundColor = "#F86F03";
      styles.color = "#fff";
      break;
  }

  return styles;
}

export default function DateInputField({ title, variant = "", value, onChange, readOnly } : ComponentProps) {
  return <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"hu"}>
      <DateTimePicker
      label={title}
      value={moment(value)}
      onChange={onChange}
      sx={returnStyles(variant)}
      disabled={!!readOnly}
    />
  </LocalizationProvider>
}
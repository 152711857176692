import {ReducerActionInterface} from "../../../lib/reducer.utils";
import {PostActionTypes} from "./post.actions";

export interface PostReducerModel
{
    title: string;
    date: Date;
    content: string;
    authorId: number;
    isModified: boolean;
}

const INITIAL_STATE: PostReducerModel = {
    authorId: 0,
    content: "",
    date: new Date(),
    title: "",
    isModified: false
}

export const postReducer = (state = INITIAL_STATE, action: ReducerActionInterface) => {
    const {type, payload} = action;

    switch (type) {
        case PostActionTypes.INIT:
            return {
                ...state,
                ...payload,
                isModified: false
            }

        case PostActionTypes.SET_MODIFIED:
            return {
                ...state,
                isModified: payload
            }

        case PostActionTypes.CLEAR_CONTENT:
            return INITIAL_STATE;

        case PostActionTypes.SET_TITLE:
            return {
                ...state,
                title: payload,
                isModified: !state.isModified
            }
        case PostActionTypes.SET_DATE:
            return {
                ...state,
                date: payload,
                isModified: !state.isModified
            }
        case PostActionTypes.SET_CONTENT:
            return {
                ...state,
                content: payload,
            }
        case PostActionTypes.SET_AUTHOR_ID:
            return {
                ...state,
                authorId: payload,
                isModified: !state.isModified
            }

        default:
            return state;
    }
}
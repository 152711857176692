import {PostResponseModel} from "../../../models";
import sendApiRequest, {NetworkMethods} from "./Network";

export async function getPosts() {
    try {
        const response = await sendApiRequest({
            headers: {
                "x-public-key": process.env.REACT_APP_PUBLIC_REQUEST_KEY
            },
            method: NetworkMethods.GET,
            endpoint: `post?page=1&limit=10&all=true`,
        });

        return response.data;
    } catch (error: any) {
        throw new Error(error?.message ?? "Ismeretlen hiba, keresse fel a rendszergazdát!");
    }
}

export async function getNewsletter() {
    try {
        const response = await sendApiRequest({
            headers: {
                "x-public-key": process.env.REACT_APP_PUBLIC_REQUEST_KEY
            },
            method: NetworkMethods.GET,
            endpoint: `newsletter`,
        });

        return response.data;
    } catch (error: any) {
        throw new Error(error?.message ?? "Ismeretlen hiba, keresse fel a rendszergazdát!");
    }
}

export async function getContacts() {
    try {
        const response = await sendApiRequest({
            headers: {
                "x-public-key": process.env.REACT_APP_PUBLIC_REQUEST_KEY
            },
            method: NetworkMethods.GET,
            endpoint: `contact`,
        });

        return response.data;
    } catch (error: any) {
        throw new Error(error?.message ?? "Ismeretlen hiba, keresse fel a rendszergazdát!");
    }
}

export async function getEvents(page: number = 1, limit: number = 4000) {
    try {
        const response = await sendApiRequest({
            headers: {
                "x-public-key": process.env.REACT_APP_PUBLIC_REQUEST_KEY
            },
            method: NetworkMethods.GET,
            endpoint: `event?page=${page}&limit=${limit}`,
        });

        return response.data;
    } catch (error: any) {
        throw new Error(error?.message ?? "Ismeretlen hiba, keresse fel a rendszergazdát!");
    }
}

export async function getPostBySlug(slugTitle: string) {
    try {
        const response = await sendApiRequest({
            headers: {
                "x-public-key": process.env.REACT_APP_PUBLIC_REQUEST_KEY
            },
            method: NetworkMethods.GET,
            endpoint: `post/${slugTitle}`,
        });

        return response.data;
    } catch (error: any) {
        throw new Error(error?.message ?? "Ismeretlen hiba, keresse fel a rendszergazdát!");
    }
}

export async function getSiteSettings() {
    try {
        const response = await sendApiRequest({
            headers: {
                "x-public-key": process.env.REACT_APP_PUBLIC_REQUEST_KEY
            },
            method: NetworkMethods.GET,
            endpoint: `settings`,
        });

        return response.data;
    } catch (error: any) {
        throw new Error(error?.message ?? "Ismeretlen hiba, keresse fel a rendszergazdát!");
    }
}

export async function getGallery(page: number = 1, limit: number = 2) {
    try {
        const response = await sendApiRequest({
            headers: {
                "x-public-key": process.env.REACT_APP_PUBLIC_REQUEST_KEY
            },
            method: NetworkMethods.GET,
            endpoint: `gallery?page=${page}&limit=${limit}`,
        });

        return response.data;
    } catch (error: any) {
        throw new Error(error?.message ?? "Ismeretlen hiba, keresse fel a rendszergazdát!");
    }
}

export async function uploadNewsletter(token: string, email: string) {
    try {
        const response = await sendApiRequest({
            headers: {
                Authorization: `Bearer ${token}`,
            },
            method: NetworkMethods.POST,
            endpoint: `newsletter/subscribe`,
            payload: {
                email: email
            }
        });

        return response.data;
    } catch (error: any) {
        throw new Error(error?.message ?? "Ismeretlen hiba, keresse fel a rendszergazdát!");
    }
}

export async function uploadNewEvent(token: string, payload: FormData) {
    try {
        const response = await sendApiRequest({
            headers: {
                Authorization: `Bearer ${token}`,
            },
            method: NetworkMethods.POST,
            endpoint: `event/create`,
            payload
        });

        return response.data;
    } catch (error: any) {
        throw new Error(error?.message ?? "Ismeretlen hiba, keresse fel a rendszergazdát!");
    }
}

export async function uploadNewPost(token: string, payload: FormData) {
    try {
        const response = await sendApiRequest({
            headers: {
                Authorization: `Bearer ${token}`,
            },
            method: NetworkMethods.POST,
            endpoint: `post/create`,
            payload
        });

        return response.data;
    } catch (error: any) {
        throw new Error(error?.message ?? "Ismeretlen hiba, keresse fel a rendszergazdát!");
    }
}

export async function updatePost(token: string, payload: FormData) {
    try {
        const response = await sendApiRequest({
            headers: {
                Authorization: `Bearer ${token}`,
            },
            method: NetworkMethods.POST,
            endpoint: `post/update`,
            payload
        });

        return response.data;
    } catch (error: any) {
        throw new Error(error?.message ?? "Ismeretlen hiba, keresse fel a rendszergazdát!");
    }
}

export async function updateSetting(token: string, key: string, value: string) {
    try {
        const response = await sendApiRequest({
            headers: {
                Authorization: `Bearer ${token}`,
            },
            method: NetworkMethods.POST,
            endpoint: `settings/update?key=${key}&value=${value}`,
        });

        return response.data;
    } catch (error: any) {
        throw new Error(error?.message ?? "Ismeretlen hiba, keresse fel a rendszergazdát!");
    }
}

export async function unsubscribeNewsLetter(identifier: string) {
    try {
        const response = await sendApiRequest({
            headers: {
                "x-public-key": process.env.REACT_APP_PUBLIC_REQUEST_KEY
            },
            method: NetworkMethods.POST,
            endpoint: `newsletter/unsubscribe/${identifier}`,
        });

        return response.data;
    } catch (error: any) {
        throw new Error(error?.message ?? "Ismeretlen hiba, keresse fel a rendszergazdát!");
    }
}

export async function removeImage(token: string, url: string) {
    try {
        const response = await sendApiRequest({
            headers: {
                Authorization: `Bearer ${token}`,
            },
            method: NetworkMethods.DELETE,
            endpoint: `image/remove?imageUrl=${url}`,
        });

        return response.data;
    } catch (error: any) {
        throw new Error(error?.message ?? "Ismeretlen hiba, keresse fel a rendszergazdát!");
    }
}

export async function getEventById(id: number) {
    try {
        const response = await sendApiRequest({
            headers: {
                "x-public-key": process.env.REACT_APP_PUBLIC_REQUEST_KEY
            },
            method: NetworkMethods.GET,
            endpoint: `event/${id}`,
        });

        return response.data;
    } catch (error: any) {
        throw new Error(error?.message ?? "Ismeretlen hiba, keresse fel a rendszergazdát!");
    }
}

export async function getPostById(id: number) {
    try {
        const response = await sendApiRequest({
            headers: {
                "x-public-key": process.env.REACT_APP_PUBLIC_REQUEST_KEY
            },
            method: NetworkMethods.GET,
            endpoint: `post/id/${id}`,
        });

        return response.data;
    } catch (error: any) {
        throw new Error(error?.message ?? "Ismeretlen hiba, keresse fel a rendszergazdát!");
    }
}

export async function updateEvent(token: string, payload: FormData) {
    try {
        const response = await sendApiRequest({
            headers: {
                Authorization: `Bearer ${token}`,
            },
            method: NetworkMethods.POST,
            endpoint: `event/update`,
            payload
        });

        return response.data;
    } catch (error: any) {
        throw new Error(error?.message ?? "Ismeretlen hiba, keresse fel a rendszergazdát!");
    }
}

export async function sendLogoutRequest(token: string) {
    try {
        const response = await sendApiRequest({
            headers: {
                "x-public-key": process.env.REACT_APP_PUBLIC_REQUEST_KEY
            },
            method: NetworkMethods.POST,
            endpoint: `user/sign-out`,
            payload: {
                token: token
            }
        });

        return response.data;
    } catch (error: any) {
        throw new Error(error?.message ?? "Ismeretlen hiba, keresse fel a rendszergazdát!");
    }
}

export async function removeEventRequest(token: string, id: number) {
    try {
        const response = await sendApiRequest({
            headers: {
                Authorization: `Bearer ${token}`,
                "x-public-key": process.env.REACT_APP_PUBLIC_REQUEST_KEY
            },
            method: NetworkMethods.DELETE,
            endpoint: `event/id/${id}`,
            payload: {
                token: token
            }
        });

        return response.status;
    } catch (error: any) {
        throw new Error(error?.message ?? "Ismeretlen hiba, keresse fel a rendszergazdát!");
    }
}

export async function removePostRequest(token: string, id: number) {
    try {
        const response = await sendApiRequest({
            headers: {
                Authorization: `Bearer ${token}`,
                "x-public-key": process.env.REACT_APP_PUBLIC_REQUEST_KEY
            },
            method: NetworkMethods.DELETE,
            endpoint: `post/id/${id}`,
            payload: {
                token: token
            }
        });

        return response.status;
    } catch (error: any) {
        throw new Error(error?.message ?? "Ismeretlen hiba, keresse fel a rendszergazdát!");
    }
}
import {DataGrid, GridColDef, GridEventListener, huHU} from "@mui/x-data-grid";
import EditIcon from '@mui/icons-material/Edit';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    Stack,
    Tooltip
} from "@mui/material";
import {SettingsResponseModel} from "../../../../models/settings.model";
import {TableIcon} from "./posts-table.component";
import React, {useState} from "react";
import {TransitionProps} from "@mui/material/transitions";
import {useDispatch, useSelector} from "react-redux";
import {setMessage} from "../../store/reducers/messages/messages.actions";
import {MessageTypesEnum} from "../../enums/message-types.enum";
import {updateSetting} from "../../lib/Requests";
import TextInputField from "../input/text-input-field.component";
import {getUser} from "../../store/reducers/user/user.selector";

interface ComponentProps {
    settings: SettingsResponseModel[];
}


const OPERATION_CELL_WIDTH = 70;

const columns: GridColDef[] = [
    { field: 'id', headerName: 'Id', width: 70 },
    { field: 'type', headerName: 'Kategória', width: 200 },
    { field: 'value', headerName: 'Tartalom', width: 450 },
    {
        field: 'edit', headerName: '', width: OPERATION_CELL_WIDTH, renderCell: (params) => {
            return (
                <Stack flexDirection="row">
                    <TableIcon>
                        <Tooltip title={"Szerkesztés"}>
                            <EditIcon />
                        </Tooltip>
                    </TableIcon>
                </Stack>
            );
        },
    },
];

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function SettingsTable({ settings } : ComponentProps)
{
    const dispatch = useDispatch();

    const [open, setOpen] = useState<boolean>(false);
    const [type, setType] = useState<string>("");
    const [value, setValue] = useState<string>("");

    const currentUser = useSelector(getUser);

    const handleTableDataClick: GridEventListener<'cellClick'> = (params, event, details) =>
    {
        const { field, row } = params;

        if (field === 'edit') {
            setType(row.type);
            setValue(row.value);
            setOpen(true);
        }
    };

    const handleSubmit = async () => {
        try {
            await updateSetting(currentUser.access_token, type, value);

            dispatch(
                setMessage({
                    message: "Sikeres frissítés!",
                    type: MessageTypesEnum.SUCCESS
                })
            )

            setOpen(false);
        } catch (e) {
            dispatch(
                setMessage({
                    message: "Hiba történt a mentés során!",
                    type: MessageTypesEnum.ERROR
                })
            )
        }
    }

    return <div className='table'>
        <DataGrid
            className="table"
            rows={settings.map((setting: SettingsResponseModel, i: number) => ({
                ...setting,
                id: i + 1,
            }))}
            sx={{ height: 600, width: "100%" }}
            onCellClick={handleTableDataClick}
            columns={columns}
            initialState={{
                pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                },
            }}
            pageSizeOptions={[5, 10]}
            localeText={huHU.components.MuiDataGrid.defaultProps.localeText}
        />

        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setOpen(!open)}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>{"Beállítás szerkesztése"}</DialogTitle>
            <DialogContent sx={{
                minWidth: 300,
                maxWidth: 900
            }}>
                <TextInputField title={"Tartalom"} value={value} onChange={(e: any) => setValue(e.target.value)} textbox/>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(!open)}>Mégse</Button>
                <Button onClick={handleSubmit}>Szerkesztés</Button>
            </DialogActions>
        </Dialog>
    </div>;
}
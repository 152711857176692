import './sign-in.styles.css';
import { Grid, InputAdornment } from '@mui/material';

import Logo from '../../assets/logo.png';
import TextInputField from '../../components/input/text-input-field.component';
import ActionButton from '../../components/button/button.component';
import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { setCurrentUser } from '../../store/reducers/user/user.actions';
import sendApiRequest, { NetworkMethods } from '../../lib/Network';
import { UserResponse } from '../../models/api-user-response.model';
import { setMessage } from '../../store/reducers/messages/messages.actions';
import { MessageTypesEnum } from '../../enums/message-types.enum';

export default function SignIn() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSent, setIsSent] = useState<boolean>(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleClickShowPassword = () => setIsPasswordVisible(!isPasswordVisible);

  const blockSubmitButton = () => {
    setIsSent(true);
    setTimeout(() => {
      setIsSent(false);
    }, 6000);
  }

  const onSubmit = async () => {
    if (!(email && password)) {
      dispatch(setMessage({
        message: 'Hiba! Email/Jelszó mező üres!',
        type: MessageTypesEnum.ERROR,
      }));
      blockSubmitButton();
      return;
    }

    blockSubmitButton();

    try {
      const response = await sendApiRequest({
        method: NetworkMethods.POST,
        endpoint: 'user/sign-in',
        headers: {
          "x-public-key": process.env.REACT_APP_PUBLIC_REQUEST_KEY,
        },
        payload: {
          email: email,
          password: password,
        },
      });

      const statusCode: number = response.status;

      if (statusCode < 300 && statusCode < 200) {
        dispatch(setMessage({
          message: 'Hiba! Sikertelen belépés! Ellenőrizd az adataidat!',
          type: MessageTypesEnum.ERROR,
        }));
        setEmail('');
        setPassword('');
        return;
      }

      const { name, token, email: responseMail, isAdmin, id }: UserResponse = response.data;

      // User signed in.
      dispatch(setCurrentUser({
        id,
        name,
        access_token: token,
        email: responseMail,
        isAdmin,
        isAuthenticated: true
      }));

      // Navigate user to home page.
      navigate('/');

      // Success message
      dispatch(setMessage({
        message: `Szép napot ${name}!`,
        type: MessageTypesEnum.INFO,
      }));
    } catch (e: any) {
      dispatch(setMessage({
        message: 'Hiba! Sikertelen belépés! Ellenőrizd az adataidat!',
        type: MessageTypesEnum.ERROR,
      }));
    }
  };

  // For the layout
  return <Grid container justifyContent='center' alignItems='center' className='sign-in-container'>
    {/* Move the div to the center */}
    <Grid item className='sign-in-container-box'>
      {/* Move the inputs center inside the centered div */}
      <Grid container direction='column' justifyContent='center' alignItems='center' className='sign-in-box' gap={2}>
        <Grid item>
          <img src={Logo} className='sign-in-container-box-image' alt={'Raktárkezelő rendszer'} />
        </Grid>
        <Grid item>
          <Typography variant='h3' textAlign="center">Kőszegszerdahely Római Katolikus Plébánia</Typography>
        </Grid>
        <Grid item>
          <TextInputField title={'Email'} variant='DARK' value={email} onChange={onEmailChange} />
          <TextInputField title={'Jelszó'} type={!isPasswordVisible ? 'password' : 'text'} variant='DARK'
                          value={password} onChange={onPasswordChange}  />
        </Grid>
        <Grid item>
          <ActionButton title={'Bejelentkezés'} type='DARK' action={onSubmit} disabled={isSent} />
          <br />
        </Grid>
      </Grid>
    </Grid>
  </Grid>;
}
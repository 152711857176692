import {ReducerActionInterface} from "../../../lib/reducer.utils";
import {NewsletterModel} from "../../../../../models";
import {NewsletterActionTypes} from "./newsletter.actions";

export const INITIAL_STATE: NewsletterModel = {
    email: ""
}

export const newsletterReducer = (state = INITIAL_STATE, action: ReducerActionInterface) => {
    const { type, payload } = action;

    switch (type) {
        case NewsletterActionTypes.SET_EMAIL:
            return {
                ...state,
                email: payload
            }

        default: return state;
    }
}
import {ReducerActionInterface} from "../../../lib/reducer.utils";
import {EventModel} from "../../../../../models";
import {EventsActionTypes} from "./events.actions";

const INITIAL_STATE: EventModel = {
    content: "",
    date: new Date(),
    permament: false,
    place: "",
    title: ""
}

export const eventsReducer = (state = INITIAL_STATE, action: ReducerActionInterface) => {
    const { type, payload } = action;

    switch (type) {
        case EventsActionTypes.INIT_EVENT:
            return payload;

        case EventsActionTypes.CLEAR_CONTENT: return INITIAL_STATE;
        case EventsActionTypes.SET_TITLE:
            return {
                ...state,
                title: payload
            }
        case EventsActionTypes.SET_DATE:
            return {
                ...state,
                date: payload
            }
        case EventsActionTypes.SET_CONTENT:
            return {
                ...state,
                content: payload
            }
        case EventsActionTypes.SET_PERMAMENT:
            return {
                ...state,
                permament: payload
            }
        case EventsActionTypes.SET_PLACE:
            return {
                ...state,
                place: payload
            }

        default: return state;
    }
}
import {UserModel} from "../../../../../models";
import SiteBreadCrumb from "../../../components/page-breadcrumb/page-breadcrumb.component";
import Typography from "@mui/material/Typography";
import SingleImageUpload from "../../../components/input/single-image-upload.component";
import TextInputField from "../../../components/input/text-input-field.component";
import DateInputField from "../../../components/input/date-input-field.component";
import MyEditor from "../../../components/text-editor/MyEditor.component";
import MultipleImageUpload from "../../../components/input/multiple-image-upload.component";
import ActionButton from "../../../components/button/button.component";
import React, {useRef} from "react";
import {ImageModel} from "../../../components/input/models";
import {uploadNewPost} from "../../../lib/Requests";
import {setMessage} from "../../../store/reducers/messages/messages.actions";
import {MessageTypesEnum} from "../../../enums/message-types.enum";
import {clearPostContent, setPostContent, setPostDate, setPostTitle} from "../../../store/reducers/posts/post.actions";
import {useDispatch, useSelector} from "react-redux";
import {getUser} from "../../../store/reducers/user/user.selector";
import {
    getImageManagementUploadedFile,
    getImageManagementUploadedFiles
} from "../../../store/reducers/image-management/selectors/image-management.selectors";
import {getPostData} from "../../../store/reducers/posts/post.selectors";
import {imageManagementClearContent} from "../../../store/reducers/image-management/actions/image-management.actions";
import {Editor} from "@tinymce/tinymce-react";

const Sites = [
    {
        name: "Főoldal",
        url: "/"
    },
    {
        name: "Posztok",
        url: "/posztok"
    },
    {
        name: "Új poszt",
        url: "/posztok/uj-poszt"
    }
]


export default function CreatePostPage({user}: { user: UserModel }) {
    const dispatch = useDispatch();
    const editorRef: any = useRef("");

    const currentUser = useSelector(getUser);
    const previewImage = useSelector(getImageManagementUploadedFile);
    const galleryImages = useSelector(getImageManagementUploadedFiles);
    const postData = useSelector(getPostData);
    const {title, date, content, authorId} = postData;

    const onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => dispatch(setPostTitle(e.target.value));
    const onDateChange = (e: any) => dispatch(setPostDate(e['_d']));

    const onSubmit = async () => {
        try {
            const formData = new FormData();

            formData.append("preview", previewImage?.file as File);
            galleryImages?.forEach((img: ImageModel) => {
                formData.append("gallery", img?.file as File);
            })
            formData.append("title", title);
            formData.append("content", editorRef.current.getContent());
            formData.append("date", date.toISOString());
            formData.append("authorId", `${currentUser.id}`);

            await uploadNewPost(currentUser.access_token, formData);

            dispatch(setMessage({
                message: `Sikeres feltöltés!`,
                type: MessageTypesEnum.SUCCESS,
            }));

            dispatch(clearPostContent());
            dispatch(imageManagementClearContent())
        } catch (e) {
            dispatch(setMessage({
                message: `A feltöltés sikertelen!`,
                type: MessageTypesEnum.ERROR,
            }));
        }
    }

    return <div>
        <SiteBreadCrumb sites={Sites}/>
        <Typography variant='h3'>Poszt létrehozás</Typography>
        <br/>
        <br/>
        <SingleImageUpload/>
        <TextInputField title={'Cím'} value={title} onChange={onTitleChange}/>
        <DateInputField title={'Dátum'} value={date} onChange={onDateChange}/>
        <Editor
            apiKey='0yrg1pfvynx5376eilix6n3h5zc647qwk8ipoffz3eep91im'
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={content}
            init={{
                language: 'hu_HU',
                height: 500,
                menubar: false,
                plugins: [
                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                    'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                ],
                toolbar: 'undo redo | blocks | ' +
                    'bold italic forecolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            }}/>
        <MultipleImageUpload/>
        <br/>
        <ActionButton title={'Hozzáadás'} type='SAVE' action={onSubmit}/>
        <br/>
        <br/>
    </div>
}

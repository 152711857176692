import "./service.styles.css"
import {Link, useNavigate} from "react-router-dom";
import Typography from "@mui/material/Typography";

interface ComponentProps {
    title: string;
    image: any;
    url: string;
    textColor: string;
    backgroundColor: string;
}

export default function Service({title, image, url, textColor, backgroundColor}: ComponentProps) {
    return <div className="service-container" style={{backgroundColor: backgroundColor, color: textColor}}>
        <Link to={url}>
            <img src={image} title={title} alt={title} className="ervice-container img" />
            <Typography variant="h4" align="center" sx={{
                fontWeight: 800
            }}>{title}</Typography>
        </Link>
    </div>
}
import "./header.styles.css";

import HomeIcon from '@mui/icons-material/Home';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import HandshakeIcon from '@mui/icons-material/Handshake';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone';
import MenuIcon from '@mui/icons-material/Menu';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';

import Typography from '@mui/material/Typography';
import * as React from 'react';
import {useState} from 'react';
import {
    Drawer,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText, Stack,
} from '@mui/material';
import {Link, useNavigate} from 'react-router-dom';
import Logo from '../../assets/logo.png';
import {HeaderDrawer, HeaderGrid, HeaderLogo, HeaderMainNavigation, MyListItem, ToggleBtn} from './header.styled';

export const menus = [
    {
        title: "Főoldal",
        url: '/',
        icon: <HomeIcon/>
    },
    {
        title: "Posztok",
        url: '/posztok',
        icon: <ReceiptLongIcon/>
    },
    {
        title: "Üzenetek",
        url: '/uzenetek',
        icon: <LocalOfferIcon/>
    },
    {
        title: "Hírlevél",
        url: '/hirlevel',
        icon: <HandshakeIcon/>
    },
    {
        title: "Események",
        url: '/esemenyek',
        icon: <ProductionQuantityLimitsIcon/>
    },
    {
        title: "Beállítások",
        url: '/beallitasok',
        icon: <AccessTimeFilledIcon/>
    },
    {
        title: "Kijelentkezés",
        url: '/kijelentkezes',
        icon: <LogoutTwoToneIcon/>
    }
]

export default function Header() {
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);

    const navigateToHome = () => navigate('/');

    return <HeaderGrid container direction="column" justifyContent="flex-start" alignItems="center"
                       className="header-box">
        <Grid item>
            <Link to={"/"}>
                <HeaderLogo src={Logo} className='header-image' alt={'Kőszegszerdahely Római Katolikus Plébánia'}/>
            </Link>
        </Grid>

        <Grid item sx={{textAlign: "center"}}>
            <Link to={"/"}>
                <Typography variant='h4' component='div' sx={{width: "100%", height: "auto", textAlign: "center"}}>
                    Kőszegszerdahely Római Katolikus Plébánia
                </Typography>
            </Link>
        </Grid>

        <Grid item className="toggleBtn">
            <ToggleBtn onClick={() => setOpen(!open)}>
                <MenuIcon className='icon'/>
            </ToggleBtn>
        </Grid>

        <Grid item>
            <HeaderMainNavigation>
                {
                    menus.map(({title, url, icon}, i: number) => {
                        return (
                            <MyListItem key={title + " " + i}>
                                <ListItemButton onClick={() => {
                                    navigate(url)
                                }}>
                                    <ListItemIcon sx={{color: "#fff"}}>
                                        {icon}
                                    </ListItemIcon>
                                    <ListItemText primary={title}/>
                                </ListItemButton>
                            </MyListItem>
                        )
                    })
                }
            </HeaderMainNavigation>
        </Grid>

        <HeaderDrawer
            anchor={'left'}
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
        >
            <ToggleBtn onClick={() => setOpen(false)}>
                <CloseFullscreenIcon className='icon'/>
            </ToggleBtn>

            <Stack direction="row" justifyContent="center" alignItems="center">
                <br />
                <Link to={"/"}>
                    <Typography variant='h4' sx={{maxWidth: "400px", width: "100%", height: "auto", textAlign: "center"}}>
                        Kőszegszerdahely Római Katolikus Plébánia
                    </Typography>
                </Link>
                <br />
            </Stack>

            <List sx={{margin: "0 auto"}}>
                {
                    menus.map(({title, url, icon}, i: number) => {
                        return (
                            <MyListItem key={title + " " + i}>
                                <ListItemButton onClick={() => {
                                    setOpen(false);
                                    navigate(url)
                                }} className={"bgg"}>
                                    <ListItemIcon sx={{color: "#fff"}}>
                                        {icon}
                                    </ListItemIcon>
                                    <ListItemText primary={title}/>
                                </ListItemButton>
                            </MyListItem>
                        )
                    })
                }
            </List>
        </HeaderDrawer>
    </HeaderGrid>
}
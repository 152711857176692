import {UserModel} from "../../store/reducers/user/user.reducer";
import SiteBreadCrumb from "../../components/page-breadcrumb/page-breadcrumb.component";
import Typography from "@mui/material/Typography";
import {useEffect, useState} from "react";
import {getContacts, getNewsletter, getPosts, getSiteSettings} from "../../lib/Requests";
import {NewsletterModel} from "../../../../models";
import NewsLetterTable from "../../components/tables/newsletter-table.component";
import {ContactModel} from "../../../../models/contact.model";
import MessagesTable from "../../components/tables/messages-table.component";

interface ComponentProps
{
    user: UserModel;
}

const Sites = [
    {
        name: "Főoldal",
        url: "/"
    },
    {
        name: "Üzenetek",
        url: "/uzenetek"
    }
]

export default function MessagesPage({ user } : ComponentProps) {
    const [messages, setMessages] = useState<ContactModel[]>([]);

    useEffect(() => {
        const collect = async () => {
            const response = await getContacts();
            setMessages(response);
        }

        collect()
    }, []);

    return <div>
        <SiteBreadCrumb sites={Sites} />

        <Typography variant='h3'>Üzenetek</Typography>

        <MessagesTable entries={messages} />
    </div>
}
import {UserModel} from "../../store/reducers/user/user.reducer";
import SiteBreadCrumb from "../../components/page-breadcrumb/page-breadcrumb.component";
import Typography from "@mui/material/Typography";
import React, {useEffect, useState} from "react";
import Service from "../../components/service/service.component";
import PostImage from "../../assets/instagram-post.png";
import {Stack} from "@mui/material";

interface ComponentProps
{
    user: UserModel;
}

const Sites = [
    {
        name: "Főoldal",
        url: "/"
    },
    {
        name: "Posztok",
        url: "/posztok"
    }
]

export default function PostPage() {
    return <div>
        <SiteBreadCrumb sites={Sites} />
        <Typography variant='h3'>Posztok</Typography>
        <Stack direction="row" spacing={2} flexWrap={"wrap"}>
            <Service title={"Poszt létrehozása"} image={PostImage} url={"/posztok/uj-poszt"} textColor={"#fff"} backgroundColor={"#000"} />
            <Service title={"Meglévő posztok"} image={PostImage} url={"/posztok/lista"} textColor={"#fff"} backgroundColor={"#000"} />
        </Stack>
    </div>
}
import {UserModel} from "../../store/reducers/user/user.reducer";
import SiteBreadCrumb from "../../components/page-breadcrumb/page-breadcrumb.component";
import Typography from "@mui/material/Typography";
import React, {useEffect, useState} from "react";
import {getNewsletter, getPosts, getSiteSettings, uploadNewsletter} from "../../lib/Requests";
import {NewsletterModel} from "../../../../models";
import NewsLetterTable from "../../components/tables/newsletter-table.component";
import {useDispatch, useSelector} from "react-redux";
import {setMessage} from "../../store/reducers/messages/messages.actions";
import {MessageTypesEnum} from "../../enums/message-types.enum";
import ActionButton from "../../components/button/button.component";
import TextInputField from "../../components/input/text-input-field.component";
import {getNewsLetterEmail} from "../../store/reducers/newsletter/newsletter.selectors";
import {setNewsLetterEmail} from "../../store/reducers/newsletter/newsletter.actions";
import {getUser} from "../../store/reducers/user/user.selector";

interface ComponentProps
{
    user: UserModel;
}

const Sites = [
    {
        name: "Főoldal",
        url: "/"
    },
    {
        name: "Hírlevél",
        url: "/hirlevel"
    }
]

export default function NewsletterPage({ user } : ComponentProps) {
    const dispatch = useDispatch();

    const [entries, setEntries] = useState<NewsletterModel[]>([]);

    const email = useSelector(getNewsLetterEmail);
    const currentUser = useSelector(getUser);

    const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => dispatch(setNewsLetterEmail(e.target.value));

    useEffect(() => {
        const collect = async () => {
            const response = await getNewsletter();
            setEntries(response);
        }

        collect()
    }, []);

    const onSubmit = async () => {
        try {
            await uploadNewsletter(currentUser.access_token, email);

            dispatch(setMessage({
                message: `Sikeres feltöltés!`,
                type: MessageTypesEnum.SUCCESS,
            }));

            dispatch(setNewsLetterEmail(""))

            const response = await getNewsletter();
            setEntries(response);
        } catch (e) {
            dispatch(setMessage({
                message: `A feltöltés sikertelen!`,
                type: MessageTypesEnum.ERROR,
            }));
        }
    }

    return <div>
        <SiteBreadCrumb sites={Sites} />
        <Typography variant='h3'>Feliratkozás hozzáadása</Typography>
        <TextInputField title={'E-mail cím'} value={email} onChange={onEmailChange} />
        <ActionButton title={'Hozzáadás'} type='SAVE' action={onSubmit}  />
        <br/>
        <br/>

        <Typography variant='h3'>Hírlevél feliratkozások</Typography>
        <NewsLetterTable entries={entries} />
    </div>
}
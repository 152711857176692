import {styled} from "@mui/material";

export const MyDropZone = styled("div")(({ theme }) => ({
    width: "100%",
    maxWidth: "500px",
    maxHeight: "50px",
    minHeight: "50px",
    height: "100%",
    background: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    backgroundBlendMode: "darken",
    color: '#111',
    fontWeight: "bold",
    border: "2px dashed #111"
}));
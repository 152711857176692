import {EventModel, UserModel} from "../../../../../models";
import SiteBreadCrumb from "../../../components/page-breadcrumb/page-breadcrumb.component";
import Typography from "@mui/material/Typography";
import SingleImageUpload from "../../../components/input/single-image-upload.component";
import TextInputField from "../../../components/input/text-input-field.component";
import TextSelectDropdownField from "../../../components/input/text-select-dropdown-field.component";
import DateInputField from "../../../components/input/date-input-field.component";
import {Checkbox, ListItemButton, Stack} from "@mui/material";
import ActionButton from "../../../components/button/button.component";
import React, {useEffect} from "react";
import {
    initEvent,
    setEventDate,
    setEventPermament,
    setEventPlace,
    setEventTitle
} from "../../../store/reducers/events/events.actions";
import {getEventById, removeEventRequest, updateEvent,} from "../../../lib/Requests";
import {setMessage} from "../../../store/reducers/messages/messages.actions";
import {MessageTypesEnum} from "../../../enums/message-types.enum";
import {useDispatch, useSelector} from "react-redux";
import {
    getImageManagementUploadedFile, isImageModified
} from "../../../store/reducers/image-management/selectors/image-management.selectors";
import {getEventData} from "../../../store/reducers/events/events.selectors";
import {
    imageManagementInitialize
} from "../../../store/reducers/image-management/actions/image-management.actions";
import {useNavigate, useParams} from "react-router-dom";
import {getUser} from "../../../store/reducers/user/user.selector";

const Sites = [
    {
        name: "Főoldal",
        url: "/"
    },
    {
        name: "Események",
        url: "/esemenyek"
    },
    {
        name: "Esemény szerkesztése",
        url: "/esemenyek"
    }
]

const defaultPlaces = [
    {
        title: 'Kőszegszerdahely',
        value: 'Kőszegszerdahely',
    },
    {
        title: 'Bozsok',
        value: 'Bozsok',
    },
    {
        title: 'Velem',
        value: 'Velem',
    },
    {
        title: 'Kőszegdoroszló',
        value: 'Kőszegdoroszló',
    },
]

export default function EditEventPage({user}: { user: UserModel }) {
    const {id} = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const imageModified = useSelector(isImageModified);

    const uploadedPreview = useSelector(getImageManagementUploadedFile);
    const currentUser = useSelector(getUser);
    const eventData: EventModel = useSelector(getEventData);
    const {title, content, permament, date, place} = eventData;

    useEffect(() => {
        const collect = async () => {
            const response = await getEventById(Number(id));

            dispatch(imageManagementInitialize(
                response.preview.url,
                []
            ))
            dispatch(initEvent(response));
        }

        collect();
    }, []);

    const onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => dispatch(setEventTitle(e.target.value));
    const onPermamentChange = (status: boolean) => dispatch(setEventPermament(status));
    const onDateChange = (e: any) => dispatch(setEventDate(e['_d']));
    const onPlaceChange = (e: React.ChangeEvent<HTMLInputElement>) => dispatch(setEventPlace(e.target.value));

    const onSubmit = async () => {
        try {
            const formData = new FormData();
            formData.append("id", `${id}`);

            if (imageModified) {
                if (uploadedPreview) {
                    formData.append("newPreview", uploadedPreview.file as File);
                }
            }

            formData.append("title", title);
            formData.append("permament", `${permament}`);
            formData.append("date", new Date(date).toISOString());
            formData.append("place", place);

            await updateEvent(currentUser.access_token, formData);
            dispatch(setMessage({
                message: `Sikeres frissítés!`,
                type: MessageTypesEnum.SUCCESS,
            }));
        } catch (e: any) {
            if (e.message) {
                dispatch(setMessage({
                    message: `${e.message}`,
                    type: MessageTypesEnum.ERROR,
                }));
            } else {
                dispatch(setMessage({
                    message: `A feltöltés sikertelen!`,
                    type: MessageTypesEnum.ERROR,
                }));
            }
        }
    }

    const onDelete = async () => {
        try {
            await removeEventRequest(currentUser.access_token, Number(id));
            dispatch(setMessage({
                message: `Sikeres törlés!`,
                type: MessageTypesEnum.SUCCESS,
            }));

            dispatch(setMessage({
                message: `Átirányítás...`,
                type: MessageTypesEnum.INFO,
            }));

            setTimeout(() => {
                navigate("/esemenyek");
            }, 1000);
        } catch (e: any) {
            if (e.message) {
                dispatch(setMessage({
                    message: `${e.message}`,
                    type: MessageTypesEnum.ERROR,
                }));
            } else {
                dispatch(setMessage({
                    message: `A feltöltés sikertelen!`,
                    type: MessageTypesEnum.ERROR,
                }));
            }
        }
    }

    return <div>
        <SiteBreadCrumb sites={Sites}/>

        <Typography variant='h3'>Esemény létrehozás</Typography>
        <br/>
        <br/>
        <SingleImageUpload/>
        <TextInputField title={'Cím'} value={title} onChange={onTitleChange}/>
        <TextSelectDropdownField value={place} onChange={onPlaceChange} data={defaultPlaces}
                                 title={'Helyszín'}/>
        <DateInputField title={'Dátum'} value={date} onChange={onDateChange}/>
        <ListItemButton onClick={() => onPermamentChange(!permament)}>
            <Checkbox
                checked={permament}
                inputProps={{'aria-label': 'controlled'}}
            />
            <Typography variant='body1'>Állandó-e ?</Typography>
        </ListItemButton>
        <br/>
        <br/>
        <Stack flexDirection={"row"} justifyContent={"flex-end"} flexWrap={"nowrap"} gap={2}>
            <ActionButton title={'Szerkesztés'} type='SAVE' action={onSubmit}/>
            <ActionButton title={'Törlés'} type='DELETE' action={onDelete}/>
        </Stack>
        <br/>
        <br/>
    </div>
}
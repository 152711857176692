import {UserActionTypes} from "./user.actions";
import {ReducerActionInterface} from "../../../lib/reducer.utils";

export interface UserModel {
    id: number;
    name: string;
    email: string;
    access_token: string;
    isAdmin: boolean;
    isAuthenticated: boolean;
}

/* use in the selectors */
const INITIAL_STATE: UserModel = {
    id: 1,
    name: "",
    email: "",
    access_token: "",
    isAdmin: false,
    isAuthenticated: false
}

export const userReducer = (state: UserModel = INITIAL_STATE, action: ReducerActionInterface) => {
    const {type, payload} = action;

    switch (type) {
        case UserActionTypes.SET_TOKEN:
            return {
                ...state,
                access_token: payload
            }
        case UserActionTypes.GET_TOKEN:
            return state.access_token
        case UserActionTypes.UPDATE_USER:
            return {
                ...state,
                username: payload.username,
                isAdmin: payload.isAdmin,
                name: payload.name
            }
        case UserActionTypes.SET_USER:
            return {
                id: payload?.id ? payload.id :  1,
                username: payload?.username ?? null,
                access_token: payload?.access_token ?? null,
                isAdmin: payload?.isAdmin ?? false,
                name: payload?.name ?? null
            }
        case UserActionTypes.GET_USER:
            return {
                name: state.name,
                access_token: state.access_token,
                isAdmin: state.isAdmin
            };
        case UserActionTypes.SET_IS_AUTHENTICATED:
            return {
                ...state,
                isAuthenticated: payload
            }
        default:
            return state;
    }
};
import './nav-bar.styles.css';
import { Avatar, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { getUser } from '../../store/reducers/user/user.selector';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';

import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import AdminIcon from "../../assets/admin_icon.png";
import EmployeeIcon from "../../assets/employee_icon.png";

export default function NavBar() {
  const [time, setTime] = useState({
    minutes: new Date().getMinutes(),
    hours: new Date().getHours(),
    seconds: new Date().getSeconds(),
  });

  const currentUser = useSelector(getUser);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const date = new Date();
      setTime({
        minutes: date.getMinutes(),
        hours: date.getHours(),
        seconds: date.getSeconds(),
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const convertToTwoDigit = (number: number) => {
    return number.toLocaleString('hu-HU', {
      minimumIntegerDigits: 2,
    });
  };

  const getTime = () => {
    return `${moment().format('YYYY.MM.DD')}, ${convertToTwoDigit(time.hours)}:${convertToTwoDigit(time.minutes)}`;
  };

  return <Grid container direction='row'>
    <Grid item className='nav-bar-item-container'>
        <AccessTimeFilledIcon />
        <Typography variant='h5'>{getTime()}</Typography>
    </Grid>
  </Grid>;
}